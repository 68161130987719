






























































































import { defineComponent } from '@vue/composition-api';

import Persons from '@/components/Persons.vue';

interface Person {
  name: string;
  email?: string;
  image: string;
  rank: string;
}

export default defineComponent({
  name: 'ProfileLdf',
  // props: {
  // },
  components: {
    Persons
  },
  setup: () => {
    const personsLdf: Person[] = [
      {
        name: 'Frank Becker',
        email: 'ldf@feuerwehr-ev.de',
        image: require('@/assets/profile_ldf.jpg'),
        rank: 'Leiter der Feuerwehr'
      },
      {
        name: 'Martin Hülsmann',
        email: 'stellvldf@feuerwehr-ev.de',
        image: require('@/assets/profile_stvldf.jpg'),
        rank: 'Stellv. Leiter der Feuerwehr'
      },
      {
        name: 'Manuel Kortenjan',
        email: 'stellvldf@feuerwehr-ev.de',
        image: require('@/assets/profile_stvldf_2.png'),
        rank: 'Stellv. Leiter der Feuerwehr'
      }
    ];

    const personsEvZf: Person[] = [
      {
        name: 'Markus Averbeck',
        email: 'zf-ev@feuerwehr-ev.de',
        image: require('@/assets/profile_ldf_ev_zf.jpeg'),
        rank: 'Zugführer'
      },
      {
        name: 'Andreas Enseling',
        image: require('@/assets/profile_ldf_ev_zf_stv1.jpeg'),
        rank: 'Stellv. Zugführer'
      },
      {
        name: 'Gregor Mefus',
        image: require('@/assets/profile_ldf_ev_zf_stv2.jpeg'),
        rank: 'Stellv. Zugführer'
      }
    ];

    const personsAvZf: Person[] = [
      {
        name: 'Alexander Wiesmann',
        email: 'zf-alv@feuerwehr-ev.de',
        image: require('@/assets/profile_ldf_al_zf.jpeg'),
        rank: 'Zugführer'
      },
      {
        name: 'Frederick Schröder',
        image: require('@/assets/profile_ldf_al_zf_stv1.jpeg'),
        rank: 'Stellv. Zugführer'
      }
    ];
    return {
      personsLdf,
      personsEvZf,
      personsAvZf
    };
  }
});
