














































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Persons',
  props: {
    persons: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  },
  components: {},
  setup: () => {
    return {};
  }
});
